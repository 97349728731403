<template>
  <AppPage
    hideBackIcon
    hideBottomBar
    hideAppBar
    container
    :loading="oAuthLoading"
  >
    <div class="auth-container">
      <div class="login">
        <div>
          <h2>{{ $vuetify.lang.t('$vuetify.login') }}</h2>
          <p class="subtitle-1">
            {{ $vuetify.lang.t('$vuetify.enter_your_email_and_password') }}
          </p>

          <v-form ref="form" v-model="isFormValid" class="form">
            <v-text-field
              v-model="form.email"
              outlined
              hide-details
              autofocus
              class="rounded-lg mb-3"
              type="email"
              :rules="[rules.required, rules.validEmail]"
              :label="$vuetify.lang.t('$vuetify.email')"
            ></v-text-field>

            <v-text-field
              v-model="form.password"
              outlined
              :label="$vuetify.lang.t('$vuetify.password')"
              class="rounded-lg"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="[rules.required, rules.minPasswordLength]"
              hide-details
            ></v-text-field>
            <app-button
              class="mb-3"
              :disabled="!isFormValid"
              :loading="loading"
              @click="login"
              type="submit"
              >{{ $vuetify.lang.t('$vuetify.login') }}</app-button
            >
          </v-form>

          <div class="d-flex align-items-end justify-content-between pb-3">
            <router-link
              to="forgot-password"
              class="subtitle-1 grey--text text--darken-4 text-decoration-none"
              >{{
                $vuetify.lang.t('$vuetify.forgot_your_password')
              }}</router-link
            >
            <router-link
              to="/terms-conditions"
              class="subtitle-2 text-primary text-decoration-none"
              >{{
                $vuetify.lang.t('$vuetify.terms_and_conditions')
              }}</router-link
            >
          </div>

          <label for="terms" class="terms-label">
            <v-checkbox v-model="terms" hide-details class="mb-0 pb-0">
              <template v-slot:label>
                <p class="mb-0">
                  {{ $vuetify.lang.t("$vuetify.terms_check_label1") }}
                  <router-link
                    to="/terms-conditions"
                    class="subtitle-2 text-primary  d-inline-block"
                    >{{
                      $vuetify.lang.t("$vuetify.terms_and_conditions")
                    }}</router-link
                  >.
                </p>
              </template>
            </v-checkbox>
          </label>

        </div>
      </div>

      <div class="text-center btn-container">
        <div>
          <app-button outlined to="/register">{{
            $vuetify.lang.t('$vuetify.create_new_account')
          }}</app-button>

          <p
            class="subtitle-2 my-4 grey--text text--darken-2 font-weight-regular"
          >
            {{ $vuetify.lang.t('$vuetify.or_signup_with') }}
          </p>
        </div>

        <div class="mt-4">
          <v-btn
            icon
            color="primary"
            x-large
            class="mr-5"
            @click="loginWith('facebook')"
          >
            <v-img src="@/components/svg/icon/facebook.svg"></v-img>
          </v-btn>
          <v-btn icon color="primary" x-large @click="loginWith('google')">
            <v-img src="@/components/svg/icon/google.svg"></v-img>
          </v-btn>
          <v-btn
            v-if="isIOS"
            fab
            dark
            small
            elevation="0"
            height="52px"
            width="52px"
            class="ml-3"
            color="black"
            @click="loginWithApple"
          >
            <v-icon size="32px" color="#fff">mdi-apple</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <v-img
      src="@/assets/images/paws-right.png"
      contain
      class="right-paw"
    ></v-img>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const userModule = createNamespacedHelpers('user');
import get from 'lodash/get';
import jwt_decode from 'jwt-decode';

import { validEmail, required, minPasswordLength } from '@/helpers/form-rules';
import oAuthProvider from '@/firebase';
import { uploadDeviceToken } from '@/helpers';
import { API_END_POINT } from '@/config';

import MixDevice from '@/mixin/mix-device.js';
// import { SafariViewController } from "@awesome-cordova-plugins/safari-view-controller";
// import { Browser } from "@capacitor/browser";
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser';

export default {
  name: 'login',
  mixins: [MixDevice],
  data() {
    return {
      showPassword: false,
      isFormValid: false,
      oAuthLoading: false,
      terms:false,
      form: {
        email: '',
        password: '',
        role: 'user',
      },
      count: 1,
    };
  },
  computed: {
    ...mapState(['isCordova']),
    ...userModule.mapState(['loading']),
    rules() {
      return {
        validEmail,
        required,
        minPasswordLength,
      };
    },
  },
  methods: {
    ...userModule.mapActions(['LOGIN']),
    ...userModule.mapMutations(['SET_USER']),
    checkTerms(){
      if(this.terms) return true;
      window.showAlert("Please accept the terms and conditions" , "Accept Terms");
      return false
    },
    iosGoogleLogin(url) {
      if(!this.checkTerms()) return
      let options = {
        location: 'yes',
        closebuttoncaption: 'Close',
        hidenavigationbuttons: 'yes',
        hideurlbar: 'yes',
        presentationstyle: 'pagesheet',
        toolbarposition: 'top',
        toolbartranslucent: 'yes',
      };

      let browser = InAppBrowserObject;
      browser = InAppBrowser.create(url, '_blank', options);

      browser.on('loadstart').subscribe((params) => {
        console.log('event');
        console.log(params.url);
        if (params.url.includes('com.mpawer.co://')) {
          const token = params.url.replace('com.mpawer.co://', '');
          console.log(token);

          if (token) {
            if (token.includes('error_msg')) {
              const msg = token.replace('error_msg=');
              window.showAlert(msg, 'Error');
            } else {
              localStorage.setItem('token', 'Bearer ' + token);
              this.$router.replace('/');
              uploadDeviceToken(true);
            }
            browser.close();
          }
        }
      });
    },
    login() {
      if(!this.checkTerms()) return
      if (this.$refs.form.validate()) {
        this.LOGIN(this.form);
      }
    },
    wait() {
      return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    },
    async loginWith(social) {
      if(!this.checkTerms()) return
      try {
        this.oAuthLoading = true;

        if ('cordova' in window) {
          if (social === 'facebook') {
            const permissions = ['public_profile', 'email'];
            window.facebookConnectPlugin.login(
              permissions,
              (resp) => {
                const {
                  authResponse: { accessToken, userID },
                } = resp;
                window.facebookConnectPlugin.api(
                  `/${userID}?fields=id,name,email,picture&access_token=${accessToken}`,
                  permissions,
                  'Get',
                  (data) => {
                    const { email, name, picture } = data;
                    const payload = {
                      token: accessToken,
                      email,
                      name,
                      social_id: userID,
                      avatar: get(picture, 'data.url', null),
                      social_provider: 'facebook',
                      role: 'user',
                    };

                    if (!email) {
                      window.showAlert(
                        "We didn't get email address from facebook. Please add email in facebook account first.",
                        'Login Failed'
                      );
                      return;
                    }

                    this.createUser(payload);
                  },
                  (err) => {
                    this.oAuthLoading = false;
                    window.showAlert(JSON.stringify(err), 'Cancelled');
                  }
                );
              },
              (err) => {
                window.showAlert(err.errorMessage, 'Cancelled');
                this.oAuthLoading = false;
              }
            );
            return;
          }

          if (this.isIOS) {
            if (social === 'google') {
              await this.iosGoogleLogin(`${API_END_POINT}/login/${social}`);
              this.oAuthLoading = false;
              return;
            }
            this.oAuthLoading = false;
          }
        }

        await this.wait();
        const provider = await oAuthProvider;
        if (social === 'facebook') {
          const resp = await provider.default.facebookSignIn();
          const { token, user } = resp;
          const payload = {
            token,
            avatar: user.photoURL,
            email: user.email,
            name: user.displayName,
            social_id: user.uid,
            social_provider: 'facebook',
            contact: '',
            gender: '',
            role: 'user',
          };

          if (user.providerData && user.providerData.length) {
            const { email, displayName, phoneNumber } = user.providerData[0];
            if (email) payload.email = email;
            if (displayName) payload.name = displayName;
            if (phoneNumber) payload.contact = phoneNumber;
          }

          this.createUser(payload);
        } else {
          const resp = await provider.default.googleSignIn();
          const { token, user } = resp;
          const payload = {
            token,
            avatar: user.photoURL,
            email: user.email,
            name: user.displayName,
            social_id: user.uid,
            social_provider: 'google',
            contact: '',
            gender: '',
            role: 'user',
          };
          this.createUser(payload);
        }
        // this.oAuthLoading = false;
      } catch (error) {
        this.oAuthLoading = false;
        if (
          error.message ===
            'Firebase: Error (auth/redirect-cancelled-by-user).' &&
          this.count <= 2
        ) {
          this.loginWith(social);
          this.count++;
        } else if (
          error.message ===
          'Firebase: Error (auth/account-exists-with-different-credential).'
        ) {
          window.showAlert(
            `You have already created your account with ${
              social == 'facebook' ? 'google' : 'facebook'
            } please try to login with that... :)`
          );
        } else {
          // window.showAlert(error.message, "Error");
          window.showAlert(
            "Couldn't fetch your information please try again later... :(",
            ''
          );
        }
      }
    },
    loginWithApple() {
      if(!this.checkTerms()) return
      window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        (data) => {
          this.oAuthLoading = true;
          const {
            email,
            user,
            identityToken,
            fullName: { nickname, givenName },
          } = data;

          const payload = {
            email,
            social_page: user,
            token: identityToken,
            name: `${givenName} ${nickname}`,
            social_provider: 'apple',
            role: 'user',
            contact: '',
            gender: '',
          };

          if (!email) {
            const decoded = jwt_decode(identityToken);
            payload.email = decoded.email;
          }

          this.createUser(payload);
          this.oAuthLoading = false;
        },
        (err) => {
          this.oAuthLoading = false;
          window.showAlert(JSON.stringify(err));
        }
      );
    },
    async createUser(payload) {
      try {
        if (payload) {
          payload.role = 'user';
          const resp = await this.axios.post(
            '/auth/login-signup-soical',
            payload
          );

          const newUser = resp.data.data.user;
          await uploadDeviceToken(true);
          this.SET_USER(newUser);
          this.$router.push('/');
        }
      } catch (error) {
        this.oAuthLoading = false;
        window.showAlert(error.toString(), 'Some Error 1');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 24px);
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  &::v-deep {
    .v-input {
      margin-bottom: 1rem !important;
    }
  }

  .form {
    margin-bottom: 1.5rem;
  }

  > div {
    width: 100%;
  }

  .login {
    padding-top: 1rem;
    transition: all 0.2s ease-in-out;

    > div {
      width: 100%;
    }

    @media screen and (min-height: 700px) {
      padding-top: 3rem;
    }
  }

  .btn-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.right-paw {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
